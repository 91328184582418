import Swiper from 'swiper/js/swiper';

function sliderTabs() {
  //slider tabs
  const sliderContainer = document.querySelector('.slider-images');
  const sliderContainerTabs = document.querySelector('.slider-tabs');

  if (sliderContainer == null) {
    return;
  }

  if (sliderContainerTabs == null) {
    return;
  }

  const sliderTabs = sliderContainerTabs.querySelector('.swiper-container');
  const pagination = sliderContainerTabs.querySelector('.slider__pagination');

  const mySliderTabs = new Swiper(sliderTabs, {
    pagination: {
      el: pagination,
      clickable: true,
    },
    allowTouchMove: true,
    breakpoints: {
      768: {
        allowTouchMove: false,
        slidesPerView: 3,
        direction: 'vertical',
      },
    },
  });

  const slider = sliderContainer.querySelector('.swiper-container');
  // const pagination = sliderContainer.querySelector('.swiper-pagination');

  const mySlider = new Swiper(slider, {
    effect: 'fade',
    // pagination: {
    // 	el: pagination,
    // },
    thumbs: {
      swiper: mySliderTabs,
    },
  });

  $('.slider-images-controls .slider__btn').on('click', function (event) {
    event.preventDefault();
    const $this = $(this);
    const target = $(this).data('index');
    $this.addClass('is-active').siblings().removeClass('is-active');
    mySlider.slideTo(target);
  });
}

function sliderArticles() {
  //slider  Articles
  const slideArticleContainer = document.querySelector('.slider-articles');

  if (slideArticleContainer == null) {
    return;
  }

  const sliderArticle = slideArticleContainer.querySelector(
    '.swiper-container',
  );
  const paginationArticle = slideArticleContainer.querySelector(
    '.slider__pagination',
  );
  const next = slideArticleContainer.querySelector('.slider__next');
  const prev = slideArticleContainer.querySelector('.slider__prev');

  const swiperArticle = new Swiper(sliderArticle, {
    // autoHeight: true,
    // setWrapperSize: true,
    spaceBetween: 20,
    loop: true,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
        loop: true,

        navigation: {
          nextEl: next,
          prevEl: prev,
        },
      },

      991: {
        slidesPerView: 3,
        spaceBetween: 20,
        loop: true,

        navigation: {
          nextEl: next,
          prevEl: prev,
        },
      },
    },

    pagination: {
      el: paginationArticle,
      clickable: true,
    },
  });
}

function sliderCards() {
  //slider cards
  const sliderCardContainer = document.querySelector('.slider-cards');

  if (sliderCardContainer == null) {
    return;
  }

  const sliderCard = sliderCardContainer.querySelector('.swiper-container');
  const paginationCard = sliderCardContainer.querySelector(
    '.slider__pagination',
  );

  const swiperCard = new Swiper(sliderCard, {
    autoHeight: true,
    watchOverflow: true,
    spaceBetween: 20,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },

    pagination: {
      el: paginationCard,
      clickable: true,
    },
  });
}

function sliderCardsSize1() {
  //slider cards size1
  const sliderCardContainer = document.querySelector('.slider-cards-size1');

  if (sliderCardContainer == null) {
    return;
  }

  const sliderCard = sliderCardContainer.querySelector('.swiper-container');
  const paginationCard = sliderCardContainer.querySelector(
    '.slider__pagination',
  );

  const swiperCard = new Swiper(sliderCard, {
    autoHeight: true,
    watchOverflow: true,
    spaceBetween: 19,
    slidesPerView: 1.05,
    centeredSlides: true,
    breakpoints: {
      800: {
        slidesPerView: 3,
        centeredSlides: false,
      },
      1024: {
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 31,
      },
    },

    pagination: {
      el: paginationCard,
      clickable: true,
    },
  });
}

function sliderTiles() {
  //slider Tiles
  const sliderTileContainer = document.querySelector('.slider-tiles');

  if (sliderTileContainer == null) {
    return;
  }

  const sliderTile = sliderTileContainer.querySelector('.swiper-container');
  const paginationTile = sliderTileContainer.querySelector(
    '.slider__pagination',
  );

  const swiperTile = new Swiper(sliderTile, {
    slidesPerView: 1.1,
    centeredSlides: true,
    spaceBetween: 18,
    pagination: {
      el: paginationTile,
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 1,
        centeredSlides: false,
      },
    },
  });
}

function slidersOutcomes() {
  //slider outcomes
  const sliderOutcomesContainer = document.querySelector('.js-slider-outcomes');

  if (sliderOutcomesContainer == null) {
    return;
  }

  const sliderOutcomes = sliderOutcomesContainer.querySelector(
    '.swiper-container',
  );

  const swiperOutcomes = new Swiper(sliderOutcomes, {
    slidesPerView: 1,
    spaceBetween: 0,
    touchRatio: 0.2,
    slideToClickedSlide: true,
    centeredSlides: true,
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 65,
      },
    },
  });

  //slider outcomes images
  const sliderOutcomesImagesContainer = document.querySelector(
    '.js-slider-outcomes-images',
  );

  if (sliderOutcomesImagesContainer == null) {
    return;
  }

  const sliderOutcomesImages = sliderOutcomesImagesContainer.querySelector(
    '.swiper-container-inner',
  );
  const scrollBar = sliderOutcomesImagesContainer.querySelector(
    '.swiper-scrollbar',
  );
  const pagination = sliderOutcomesImagesContainer.querySelector(
    '.swiper-pagination',
  );

  const swiperOutcomesImages = new Swiper(sliderOutcomesImages, {
    slidesPerView: 1.1,
    centeredSlides: true,
    slideToClickedSlide: true,
    spaceBetween: 12,
    scrollbar: {
      el: scrollBar,
      hide: false,
    },
    pagination: {
      el: pagination,
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 1.1,
        centeredSlides: true,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 1.2,
        spaceBetween: 26,
        centeredSlides: true,
      },
    },
  });

  swiperOutcomesImages.controller.control = swiperOutcomes;
  swiperOutcomes.controller.control = swiperOutcomesImages;
}

function sliderCardsData() {
  //slider cards data
  const sliderCardsDataContainer = document.querySelector(
    '.js-slider-cards-data',
  );

  if (sliderCardsDataContainer == null) {
    return;
  }

  const sliderCardsData = sliderCardsDataContainer.querySelector(
    '.swiper-container',
  );
  const paginationTile = sliderCardsDataContainer.querySelector(
    '.swiper-pagination',
  );

  const swiperCardsData = new Swiper(sliderCardsData, {
    slidesPerView: 1.15,
    spaceBetween: 16,
    centeredSlides: true,
    pagination: {
      el: paginationTile,
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: false,
      },
    },
  });
}

export default function sliderInit() {
  sliderTabs();
  sliderArticles();
  sliderCards();
  sliderCardsSize1();
  sliderTiles();
  slidersOutcomes();
  sliderCardsData();
}
