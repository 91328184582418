export default function tabs() {
  const $tabsContainers = $('.tabs');
  $tabsContainers.each(function () {
    const $tabsContainer = $(this);
    const $tabNavButton = $tabsContainer.find('.tabs__nav button');
    $tabNavButton.on('click', function (event) {
      event.preventDefault();
      const $this = $(this);
      const target = $this.data('target');

      $this.parent().addClass('is-active').siblings().removeClass('is-active');
      $(target).addClass('is-open').siblings().removeClass('is-open');
    });
  });
}
